<template>
	<!--  -->
	<el-dialog append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1000px">
		<template slot="title">
			<div>TIKTOK<span>{{$t('i18nn_766bfac938d98e04')}}</span></div>
		</template>
		<div class="" v-loading="loading">

			<!-- "tpCode":"",
					"createTimeGe":"",
					"createTimeLt":"",
					"shopId":"", -->

			<el-form ref="form" :rules="formRules" :size="''" :model="form" label-width="150px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">

				<el-form-item :label="'tpCode'" required>
					<el-select filterable clearable v-model="form.tpCode" :placeholder="$t('2ad108ab2c560530')"
						style="width: 200px;">
						<el-option v-for="item in selectOption.lgs_auth_tp_code" :key="item.code"
							:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item>

				

				<el-form-item :label="'ID'">
					<whThirdAppSelect :size="''" style="width: 300px;" @changeData="changWhThirdApp"></whThirdAppSelect>
				</el-form-item>
				
				<el-form-item :label="'时间'">
					<el-date-picker v-model="form.createTimeArr" type="datetimerange" align="right" unlink-panels
						range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
						:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd HH:mm:ss"
						format="yyyy-MM-dd HH:mm:ss" size="" :picker-options="pickerOptions" style="width: 340px">
					</el-date-picker>
				</el-form-item>

			</el-form>

		</div>

		<div slot="footer" class="">
			<el-button type="primary" plain @click="dialogFile = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button>
			<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
			<el-button type="warning" @click="pullOrderAction()"
				icon="el-icon-refresh">{{$t('i18nn_a456e74e3c8a95f2')}}</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import { exportPdfByShippingMark } from '@/utils/PdfShippingMark.js'
	// import barcode from '@/components/Common/barcode.vue';
	import whThirdAppSelect from '@/components/StorageCenter/components/whThirdAppSelect.vue';

	export default {
		props: {
			openTime: {},
			row: {}
		},
		components: {
			whThirdAppSelect
		},
		data() {
			return {
				dialogFile: false,

				// loading_sel: false,
				// pullYiCangWhData: [],
				// selPullYiCangWhData: [],
				// openTimeYiCangWh:"",
				// loadingUpload: false,
				
				loading: false,

				pickerOptions: this.$PickerDate.pickerOptions_1(),

				form: {
					"tpCode": "",
					"createTimeGe": "",
					"createTimeLt": "",
					"id": "",
					"createTimeArr": []
				},
				formRules: {},

				// filterData: {
				// 	// orderStatus: '',
				// 	// refNosStr: '',
				// 	// saleOrderCodeStr: '',
				// 	// warehouseOrderCodeStr: '',
				// 	"tpCode": "",
				// 	"createTimeGe": "",
				// 	"createTimeLt": "",
				// 	"shopId": "",
				// 	"createTimeArr": []
				// },
				
				loading_load: false,
				
				selectOption: {
					lgs_auth_tp_code: []
				},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['lgs_auth_tp_code'],
				(data) => {
					this.selectOption.lgs_auth_tp_code = data['lgs_auth_tp_code'];
				});
			// this.openTimeYiCangWh = new Date().getTime();
		},
		methods: {
			initData() {
				// this.filterData.id = this.row.id;
				// this.getPageData();
				// this.submitData = [];
				// this.filterData.refNosStr = "";
				// this.filterData.saleOrderCodeStr = "";
				// this.filterData.warehouseOrderCodeStr = "";
				// this.openTimeYiCangWh = new Date().getTime();
				// this.getPullYiCangWhQuery();
			},
			changWhThirdApp(data) {
				console.log('changWhThirdApp', data);
				this.form.id = data.id;
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			// changYiCangWh(data) {
			// 	console.log('changYiCangWh', data);
			// 	this.selPullYiCangWhData = data.map((item)=>{
			// 		return item.warehouse_id;
			// 	});
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// },
			//下载pdf
			// downLoadPdf(){
			// 	this.$nextTick(()=>{
			// 		exportPdfByShippingMark(this.priterData,"ShippingMark_"+this.row.packingNo);
			// 	});
			// },
			// 新建
			// add() {
			// 	this.submitData.push({
			// 		saleOrderCode: '', //
			// 		warehouseOrderCode: '' ,//
			// 		refNo:''
			// 	});
			// },
			// //删除
			// del(event, row, index) {
			// 	this.submitData.splice(index, 1);
			// },
			//易仓拉取查询-仓库
			// getPullYiCangWhQuery() {
			// 	// let _this = this;
			// 	this.loading_sel = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhThirdYiCangWhQuery, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log(this.$store.getters.getUserInfo);
			// 			console.log('易仓拉取查询-仓库');
			// 			console.log(data);
			// 			
			// 			this.loading_sel = false;
			// 			this.pullYiCangWhData = data.rows;

			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			// console.log('分页，请求失败');
			// 			this.loading_sel = false;
			// 		});
			// },
			//拉取最新订单
			pullOrderAction() {

				let createTimeLt = null;
				let createTimeGe = null;
				
				if(this.form.createTimeArr.length==2){
					createTimeLt = this.form.createTimeArr[0];
					createTimeGe = this.form.createTimeArr[1];
				}

				let parm = {
					"tpCode": this.form.tpCode,
					"createTimeGe": createTimeGe,
					"createTimeLt": createTimeLt,
					"id": this.form.id,
				};

				this.postData(this.$urlConfig.WhThirdTTPOrdersSync, parm, '', () => {
					this.$message({
						type: 'success',
						message: '拉取成功'
					});
					this.$emit("actionSuccess");
					this.dialogFile = false;
				});
			},
			//请求分页数据
			// getPageData() {
			// 	this.loading_load = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhPrinterShippingMark + '/' + this.filterData.id, {})
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_776496ec446f21f9'));
			// 			console.log(data);
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.rows) {
			// 				this.priterData = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('列表，请求失败');
			// 			this.$message.error('数据有误!');
			// 			this.loading_load = false;
			// 		});
			// },
			//提交信息
			postData(url, formData, type, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					this.loading = false;
					if (200 == data.code) {
						callback();
					} else {
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_ec_order_status'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {

			// 				// this.selectOption.wh_third_platform = data.data['wh_third_platform'];
			// 				this.selectOption.wh_ec_order_status = data.data['wh_ec_order_status'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t("tips.submitError");
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// .filter-name {
	// 	display: inline-block;
	// 	vertical-align: middle;
	// 	width: 150px;
	// 	text-align: right;
	// 	padding: 0 10px;
	// }
</style>