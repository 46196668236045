<template>
	<!-- <el-select
		v-model="userId"
		size="small"
		filterable
		remote
		clearable
		default-first-option
		placeholder="undefined"
		:remote-method="remoteMethod"
		@change="changeData"
		:loading="loading"
	>
		<el-option v-for="(item, index) in cusList" :key="index" :label="item.companyName" :value="item.userId"></el-option>
	</el-select> -->
	<el-select clearable filterable @change="changeData" :loading="loading" :disabled="disabled" v-model="val"
		:placeholder="$t('i18nn_2ad108ab2c560530')" :size="size" :style="{width: width+'px'}">
		<el-option v-for="(item,index) in list" :key="index" :label="item.shopName"
			:value="item.id">
			<div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.shopName }}</span>
				<span class="sel_option_code" style="">{{ item.id }}</span>
			</div>
			<!-- <div>
				{{ item.id }}
			</div> -->
		</el-option>
	</el-select>
</template>
<script>
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			width: {
				default: function() {
					return 150
				},
				// type: Number
			},
			size: {
				default: function() {
					return 'small'
				},
				type: String
			},
			// clearable:{
			//   default: function() {
			//     return true
			//   },
			//   type: Boolean
			// },
			disabled: {
				default: function() {
					return false
				},
				type: Boolean
			},
			// hasDefVal:{
			//   default: function() {
			//     return false
			//   },
			//   type: Boolean
			// },
			// di
		},
		components: {},
		data() {
			return {
				// dialogFile: false,
				// loadingUpload: false,

				val: '',
				loading: false,
				list: []
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,

				// saveErr: false,

				// listUrl: [],

				// tableData: []
			};
		},
		// watch: {
		//   openTime: function(newVal, oldVal) {
		//     console.log('openTime');
		//     this.dialogFile = true;
		//     this.initData();
		//   }
		// },
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			this.getListData();
		},
		methods: {
			init(val) {
				if (val) {
					this.val = val;
				} else {
					this.val = '';
				}
			},
			changeData(data) {
				console.log("changeData", data);
				let emit_data = {};
				if (data) {
					emit_data = this.list.find(item => {
						return item.id == data;
					})
				}
				this.$emit('changeData', emit_data)
			},
			clearData() {
				this.val = '';
			},
			// getCodeData(){
			// 	return this.whNo;
			// },
			// setDefVal(){
			// 	if(this.list.length>0){
			// 		this.init(this.list[0].code);
			// 	}
			// 	return this.whNo;
			// },
			//客户模糊搜索
			// remoteMethod(query) {
			// 	if (query !== '') {
			// 		this.getCusListData(query);
			// 	} else {
			// 		this.cusList = [];
			// 	}
			// },
			//请求
			getListData() {
				this.$http
					.put(this.$urlConfig.WhThirdShopPageList, {
						limit: 100,
						offset: 0,
					})
					.then(({
						data
					}) => {
						// console.log('查询数据字典，请求成功');
						console.log(data);
						if (200 == data.code && data.rows) {
							// this.list = data.rows;
							let list = data.rows;
							this.list = list;
							// this.list = list.map(item=>{
							// 	return {
							// 		code: item.whNo,
							// 		codeText: `${item.whName}(${item.address}.${item.city},${item.state} ${item.postalCode})`,
							// 		codeTextEn: `${item.whNameEn}(${item.address}.${item.city},${item.state} ${item.postalCode})`
							// 	}
							// });
							this.$emit('loadSuccess', this.list);
							this.$emit('loadAllSuccess', list);
							// this.selectOption.wh_vol_unit = data.data["wh_vol_unit"];
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.errorData');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						// console.log('查询数据字典接口，请求失败');
						this.$message.error(this.$t('tips.requestErrorData'));
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>