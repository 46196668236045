<template>
	<!-- <el-dialog :title="$t('i18nn_f7e99b8c203e2b07')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px"
		top="10px"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_f7e99b8c203e2b07')" append-to-body :visible.sync="dialogFile"
		:direction="'rtl'" size="1200px">

		<div class="">
			<div style="padding: 0 20px; ">


				<!-- <div v-if="tableData.packageInfoJson && tableData.packageInfoJson.packages">
					<el-table :data="tableData.packageInfoJson.packages" :border="true" size="small">
						<el-table-column prop="id" label="packageInfoJson.packages.id">
						</el-table-column>
					</el-table>
				</div> -->
				
				<div>
					<el-card>
						<div slot="header">
							<span>SKU信息</span>
						</div>
				
						<el-table :data="tableData.goodsSkuInfoJson.skuArr" :border="true" size="small"
							v-if="tableData.goodsSkuInfoJson && tableData.goodsSkuInfoJson.skuArr">
							<el-table-column type="index" width="50" align="center"
								:label="$t('Storage.tableColumn.no')"></el-table-column>
				
							<el-table-column prop="seller_sku" label="seller_sku">
							</el-table-column>
							<el-table-column prop="shipping_provider_name" label="shipping_provider_name">
							</el-table-column>
							<el-table-column prop="sku_id" label="sku_id">
							</el-table-column>
							<el-table-column prop="sku_image" label="sku_image">
								<template slot-scope="scope2">
									<div>
										<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope2.row.sku_image"
											:preview-src-list="[scope2.row.sku_image]">
											<div slot="error" class="image-slot" style="font-size: 40px;"><i
													class="el-icon-picture-outline"></i></div>
										</el-image>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="sku_name" label="sku_name">
							</el-table-column>
							<el-table-column prop="sku_type" label="sku_type">
							</el-table-column>
							<el-table-column prop="tracking_number" label="tracking_number">
							</el-table-column>
							<el-table-column prop="product_id" label="product_id">
							</el-table-column>
							<el-table-column prop="product_name" label="product_name">
								<template slot-scope="scope2">
									<el-popover placement="top" trigger="hover">
										<div class="pre-text">{{ scope2.row.product_name }}</div>
										<span class="over_ellipsis" slot="reference" style="">{{scope2.row.product_name}}</span>
									</el-popover>
								</template>
							</el-table-column>
							<el-table-column prop="rts_time" label="rts_time">
							</el-table-column>
							<el-table-column prop="sale_price" label="sale_price">
							</el-table-column>
							<el-table-column prop="seller_discount" label="seller_discount">
							</el-table-column>
							<el-table-column prop="currency" label="currency">
							</el-table-column>
							<el-table-column prop="display_status" label="display_status">
							</el-table-column>
							<el-table-column prop="is_gift" label="is_gift">
							</el-table-column>
							<el-table-column prop="original_price" label="original_price">
							</el-table-column>
							<el-table-column prop="package_status" label="package_status">
							</el-table-column>
							<el-table-column prop="platform_discount" label="platform_discount">
							</el-table-column>
							<el-table-column prop="item_tax" label="item_tax" min-width="400">
								<template slot-scope="scope2">
									<el-table :data="scope2.row.item_tax" :border="true" size="small">
										<el-table-column prop="tax_amount" label="tax_amount">
										</el-table-column>
										<el-table-column prop="tax_rate" label="tax_rate">
										</el-table-column>
										<el-table-column prop="tax_type" label="tax_type">
										</el-table-column>
									</el-table>
								</template>
							</el-table-column>
						</el-table>
					</el-card>
				</div>

				<el-descriptions title="售卖支付信息" v-if="tableData.paymentInfoJson" style="margin-top: 20px;">
					<el-descriptions-item label="currency">
						<span>
							{{tableData.paymentInfoJson.currency}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="original_shipping_fee">
						<span>
							{{tableData.paymentInfoJson.original_shipping_fee}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="original_total_product_price">
						<span>
							{{tableData.paymentInfoJson.original_total_product_price}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="platform_discount">
						<span>
							{{tableData.paymentInfoJson.platform_discount}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="product_tax">
						<span>
							{{tableData.paymentInfoJson.product_tax}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="seller_discount">
						<span>
							{{tableData.paymentInfoJson.seller_discount}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="shipping_fee">
						<span>
							{{tableData.paymentInfoJson.shipping_fee}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="shipping_fee_platform_discount">
						<span>
							{{tableData.paymentInfoJson.shipping_fee_platform_discount}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="shipping_fee_seller_discount">
						<span>
							{{tableData.paymentInfoJson.shipping_fee_seller_discount}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="sub_total">
						<span>
							{{tableData.paymentInfoJson.sub_total}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="tax">
						<span>
							{{tableData.paymentInfoJson.tax}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="total_amount">
						<span>
							{{tableData.paymentInfoJson.total_amount}}
						</span>
					</el-descriptions-item>
				</el-descriptions>

				<el-descriptions title="收件人信息" v-if="tableData.receiveInfoJson" style="margin-top: 20px;">
					<el-descriptions-item label="address_detail">
						<span>
							{{tableData.receiveInfoJson.address_detail}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="address_line1">
						<span>
							{{tableData.receiveInfoJson.address_line1}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="address_line2">
						<span>
							{{tableData.receiveInfoJson.address_line2}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="address_line3">
						<span>
							{{tableData.receiveInfoJson.address_line3}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="address_line4">
						<span>
							{{tableData.receiveInfoJson.address_line4}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="full_address">
						<span>
							{{tableData.receiveInfoJson.full_address}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="name">
						<span>
							{{tableData.receiveInfoJson.name}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="phone_number">
						<span>
							{{tableData.receiveInfoJson.phone_number}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="postal_code">
						<span>
							{{tableData.receiveInfoJson.postal_code}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="region_code">
						<span>
							{{tableData.receiveInfoJson.region_code}}
						</span>
					</el-descriptions-item>
				</el-descriptions>



				<!-- <div> -->
				<!-- <el-card> -->
				<!-- <div slot="header">
							<span>receiveInfoJson.district_info</span>
						</div> -->

				<el-table :data="tableData.receiveInfoJson.district_info" :border="true" size="small"
					v-if="tableData.receiveInfoJson && tableData.receiveInfoJson.district_info">
					<el-table-column prop="address_level" label="address_level">
					</el-table-column>
					<el-table-column prop="address_level_name" label="address_level_name">
					</el-table-column>
					<el-table-column prop="address_name" label="address_name">
					</el-table-column>
				</el-table>
				<!-- </el-card> -->

				<!-- </div> -->

				
				
				
				<el-descriptions title="其他信息" v-if="tableData.warehousInfoJson" style="margin-top: 20px;">
					<el-descriptions-item label="warehouseId">
						<span>
							{{tableData.warehousInfoJson.warehouseId}}
						</span>
					</el-descriptions-item>
					<el-descriptions-item label="packageInfoJson.packages.id">
						<span v-if="tableData.packageInfoJson.packages">
							{{tableData.packageInfoJson.packages.map(item=> item.id).join(',')}}
						</span>
					</el-descriptions-item>
				</el-descriptions>

			</div>
		</div>
		<!-- <div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{ $t('i18nn_4e9fc68608c60999') }}</el-button>
		</div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	// import FeeModelById from '@/components/StorageCenter/Other/FeeModelById.vue';

	export default {
		// name: 'HomeConfFrame',
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
		},
		components: {
			// FeeModelById
		},
		data() {
			return {
				// detTabActiveName: 'first',

				dialogFile: false,
				// multipleSelection: [],
				// expands:[],

				loading_load: false,

				// loading_det_load: false,

				// loading_count: false,
				// countData: {},

				//表格数据
				//loading,表格数据
				loading: false,
				//表格数据
				tableData: {},
				//分页数据
				pagination: this.$Utils.defaultPagination(),

				selectOption: {
					wh_no: [],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// workNo: '',
					// exprStatus: '',
					orderNumber: '',
					// orderNumberList: [],
					// orderNumberListStr: '',
					// // cusNo: '',
					// userId: '',
					// commitDate: '',
					// commitDateArr: [],
					// isLabelUrl: 1,
					// carrier: '',
					// keyword: '',
					// senderAddr: '',
					// returnAddr: '',
					// batchNo: ''
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},

		activated() {
			// console.log('WhDropShipping activated');

			// this.clearFilter();
			// if (this.$route.query && this.$route.query.orderNumber) {
			// 	this.filterData.orderNumber = this.$route.query.orderNumber;
			// }

			// this.initData();
		},
		//创建时
		created() {
			// console.log('WhDropShipping created');
			// this.clearFilter();
			// if (this.$route.query && this.$route.query.state) {
			// 	this.filterData.exprStatus = this.$route.query.state;
			// }
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoListData();
		},
		methods: {
			goBack() {
				// console.log('window.history.length',window.history.length);
				// if (window.history.length > 2) {
				// 	this.$router.go(-1);
				// } else {
				// 	this.$router.push({
				// 		name: 'WhDropShippingDashboard'
				// 	});
				// }
			},

			initData() {
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				// this.getPageData();
				this.tableData = this.row;

				// this.$nextTick(() => {
				// 	this.$refs.multipleTable2.doLayout();
				// });
				// this.getDicData();
			},
			//分页的筛选项数据
			// pageFilterData() {
			// 	// let commitDateArr = this.filterData.commitDateArr;
			// 	// let startCommitDate = '';
			// 	// let endCommitDate = '';
			// 	// if (commitDateArr && commitDateArr.length == 2) {
			// 	// 	startCommitDate = commitDateArr[0];
			// 	// 	endCommitDate = commitDateArr[1];
			// 	// }
			// 	// let orderNumberList = [];

			// 	// if (this.filterData.orderNumberListStr) {
			// 	// 	orderNumberList = this.filterData.orderNumberList;
			// 	// } else if (this.filterData.orderNumber) {
			// 	// 	orderNumberList = [this.filterData.orderNumber];
			// 	// }

			// 	return {
			// 		// workNo: this.filterData.workNo ? this.filterData.workNo : null,
			// 		// batchNo: this.filterData.batchNo ? this.filterData.batchNo : null,
			// 		// exprStatus: this.filterData.exprStatus ? this.filterData.exprStatus : null,
			// 		orderNumber: this.filterData.orderNumber ? this.filterData.orderNumber : null,
			// 		// orderNumberList: orderNumberList.length > 0 ? orderNumberList : null,
			// 		// // cusNo: this.filterData.cusNo ? this.filterData.cusNo : null,
			// 		// userId: this.filterData.userId ? this.filterData.userId : null,
			// 		// // commitDate: this.filterData.commitDate ? this.filterData.commitDate : null,

			// 		// startCommitDate: startCommitDate ? startCommitDate : null,
			// 		// endCommitDate: endCommitDate ? endCommitDate : null,

			// 		// keyword: this.filterData.keyword ? this.filterData.keyword : null,
			// 		// carrier: this.filterData.carrier ? this.filterData.carrier : null,
			// 		// isLabelUrl: this.filterData.isLabelUrl ? this.filterData.isLabelUrl : null,

			// 		// senderAddr: this.filterData.senderAddr ? this.filterData.senderAddr : null,
			// 		// returnAddr: this.filterData.returnAddr ? this.filterData.returnAddr : null
			// 		// // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
			// 	};
			// },

			// //请求分页数据
			// getPageData() {
			// 	// let _this = this;

			// 	let filterData = Object.assign({
			// 			offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
			// 			limit: this.pagination.page_size //当前页显示数目
			// 		},
			// 		this.pageFilterData()
			// 	);

			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhDropShippingPageList, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.rows && data.rows[0]) {
			// 				//表格显示数据
			// 				this.tableData = data.rows[0];
			// 				//当前数据总条数
			// 				// this.pagination.total = parseInt(data.total);
			// 				//当前页数
			// 				// this.pagination.current_page = parseInt(data.current);
			// 				//当前页条数
			// 				// this.pagination.page_size = parseInt(data.size);
			// 				// this.$message.success('商家账单列表，请求成功');
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('列表数据，请求失败！');
			// 			this.loading_load = false;
			// 		});
			// },

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},


			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback(data);
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_carrier_code', 'wh_signature_service'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				this.selectOption.wh_carrier_code = data.data['wh_carrier_code'];
			// 				this.selectOption.wh_signature_service = data.data['wh_signature_service'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// },

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>