<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">{{$t('i18nn_4f26196e75e6eb1a')}}</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">第三方应用订单</span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">

					<!-- <ul class="actionConList"> -->
					<!-- <li> -->
					<el-button type="primary" @click="commitAction($event, null)" size="small" icon="el-icon-sell">
						{{$t('i18nn_ab29edcb476d7186')}}
					</el-button>
					<!-- </li> -->
					<!-- <li> -->
					<!-- <el-button type="danger" @click="delAction($event, null)" size="small" icon="el-icon-delete">
								{{$t("FormMsg.Delete")}}
							</el-button> -->
					<!-- </li> -->
					<!-- <li> -->
					<!-- <el-button @click="removeSendAction($event, null)" type="warning" size="small"
								icon="el-icon-close">{{$t('i18nn_e9d55d41fee9a273')}}</el-button> -->
					<!-- </li> -->
					<!-- <li> -->
					<el-button type="success" @click="pullOrderAction($event, null)" size="small" icon="el-icon-refresh">
						<span>{{$t('i18nn_79205a47cd5d529c')}}</span></el-button>
					
					<el-button type="primary" @click="pullLabelsAction($event, null)" size="small" icon="el-icon-refresh">
						<span>获取TK面单</span></el-button>
					<!-- </li> -->
					<!-- <li> -->
					<!-- <el-button type="success" @click="refreshWhAction($event, null)" size="small"
								icon="el-icon-refresh">{{$t('i18nn_e99e48ab45c5e800')}}</el-button> -->
					<!-- </li> -->
					<!-- </ul> -->

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->

		<div class="filterCon" style="">
			<!-- <div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>{{i18nFormatter(item.value)}}</span>
					</el-menu-item>
				</el-menu>
			</div> -->
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="primary" @click="commitAction($event, null)" size="small" icon="el-icon-sell">
							{{$t('i18nn_ab29edcb476d7186')}}
						</el-button>
					</li>
					<li>
						<el-button type="danger" @click="delAction($event, null)" size="small" icon="el-icon-delete">
							{{$t("FormMsg.Delete")}}
						</el-button>
					</li>
					<li>
						<el-button @click="removeSendAction($event, null)" type="warning" size="small"
							icon="el-icon-close">{{$t('i18nn_e9d55d41fee9a273')}}</el-button>
					</li>
					<li>
						<el-button type="success" @click="pullOrderAction($event, null)" size="small" icon="el-icon-refresh">
							【<span>EC易仓</span><span>{{$t('i18nn_e504b9c36ebc2f6d')}}</span>】<span>{{$t('i18nn_79205a47cd5d529c')}}</span></el-button>
					</li>
					<li>
						<el-button type="success" @click="refreshWhAction($event, null)" size="small"
							icon="el-icon-refresh">{{$t('i18nn_e99e48ab45c5e800')}}</el-button>
					</li>
				</ul> -->
				<ul class="filterConList">
					<!-- <li>
						<span class="">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="small"></SelAgentUser>
					</li> -->
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							:clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
						</el-input>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button :icon="drawerFilter?'el-icon-arrow-up':'el-icon-arrow-down'" size="small" type="primary"
							@click="openMoreFilter()">
						</el-button>
					</li> -->
				</ul>
			</div>
		</div>
		<!--更多筛选-->
		<!-- <el-drawer append-to-body :wrapperClosable="false" custom-class="drawerFilter" :title="$t('i18nn_c4ca802cf2230b0b')" :visible.sync="drawerFilter" :direction="'rtl'"> -->
		<!-- <div class="drawerFilterCon" v-show="drawerFilter">
			<ul class="drawerFilterList">
				<li>
					<span class="filterName">{{$t('i18nn_c944a6686d996ab3')}}</span>
					<whNoSelect @changeData="changWhNo" size="small"></whNoSelect>
				</li>
				<li>
					<span class="filterName">{{$t('i18nn_d2dd21a61c559695')}}</span>
					<el-select filterable clearable size="small" v-model="filterData.thirdPlatformCode"
						:placeholder="$t('FormMsg.Please_select')" style="width: 100px;" @change="initData()">
						<el-option v-for="item in selectOption.wh_third_platform" :key="item.code"
							:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
					</el-select>
				</li>
				<li class="">
					<span class="filterName">{{$t('i18nn_bdf9dc871938ad08')}}</span>
					<el-select filterable clearable size="small" v-model="filterData.wh_thrid_Is_sync_Label"
						:placeholder="$t('FormMsg.Please_select')" style="width: 100px;" @change="initData()">
						<el-option v-for="item in selectOption.wh_thrid_Is_sync_Label" :key="item.code"
							:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</li>
				<li>
					<span class="filterName">{{$t('i18nn_b30717f74d1d91b3')}}</span>
					<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.thirdOrderNumber"
						maxlength="50" :clearable="true" style="width: 150px;"></el-input>
				</li>
				<li>
					<span class="filterName">{{$t('i18nn_737061cefdb6d0af')}}</span>
					<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.onlineOrderId"
						maxlength="50" :clearable="true" style="width: 150px;">
					</el-input>
				</li>
				<li>
					<span class="filterName">{{$t('i18nn_b6b08a7daa1de1ab')}}</span>
					<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.shopName" maxlength="50"
						:clearable="true" style="width: 150px;"></el-input>
				</li>

				<li class="filterBtnCon">
					<el-button icon="el-icon-search" size="small" type="primary"
						@click="queryMoreFilter()">{{$t('i18nn_1e7246dd6ccc5539')}}</el-button>
				</li>
			</ul>
		</div> -->
		<!-- </el-drawer> -->

		<!-- <div class="wh-filter-con">
			<div class="">
				<span>{{$t('Storage.packing_list.status')}}</span>
				<el-radio-group v-model="filterData.status" size="medium" @change="initData()">
					<el-radio-button border v-for="item in selectOption.statusList" :key="item.value"
						:label="item.value">
						{{item.label}}
					</el-radio-button>
				</el-radio-group>
			</div>
		</div> -->
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{$t('i18nn_f77f473d27f48bdc')}}</span>
				<SelAgentUser @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo"></whNoSelect>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_6235565b185f0725')}}</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.workNo"
					maxlength="50" :clearable="true" style="width: 200px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_b30717f74d1d91b3')}}</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.thirdOrderNumber"
					maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_737061cefdb6d0af')}}</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.onlineOrderId"
					maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_b6b08a7daa1de1ab')}}</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.shopName"
					maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_d2dd21a61c559695')}}</span>
				<el-select filterable size="small" v-model="filterData.thirdPlatformCode"
					:placeholder="$t('FormMsg.Please_select')" style="width: 100px;" @change="initData()">
					<el-option v-for="item in selectOption.wh_third_platform" :key="item.code" :label="$Utils.i18nCodeText(item)"
						:value="item.code"></el-option>
				</el-select>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_f353b1ebbdc25be4')}}</span>
				<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
					maxlength="50" :clearable="true" style="width: 150px;">
				</el-input>
			</div>

			<el-button type="primary" @click="initData" size="small">{{$t('FormMsg.Search')}}</el-button>
		</div> -->

		<!-- <div class="wh-action-con">
			<el-button type="primary" @click="commitAction($event, null)" size="small" icon="el-icon-sell">
				{{$t('i18nn_ab29edcb476d7186')}}</el-button>
				
			<el-button type="danger" @click="delAction($event, null)" size="small" icon="el-icon-delete">
				{{$t("FormMsg.Delete")}}</el-button>	
				
			<el-button type="success" @click="pullOrderAction($event, null)" size="small" icon="el-icon-refresh">
				【易仓平台】拉取最新订单</el-button>

			<el-button type="success" @click="refreshWhAction($event, null)" size="small" icon="el-icon-refresh">{{$t('i18nn_e99e48ab45c5e800')}}</el-button>

		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1" @selection-change="handleSelectionChange"
				@row-click="handleCurrentChange">

				<el-table-column type="selection" fixed="left" align="center" width="50">
				</el-table-column>

				<!-- <el-table-column type="expand" fixed="left" width="50">
					<template slot-scope="scope">
						<div style="margin-left: 150px; width: 1200px">

							<el-descriptions title="warehousInfoJson" v-if="scope.row.warehousInfoJson">
								<el-descriptions-item label="warehouseId">
									<span>
										{{scope.row.warehousInfoJson.warehouseId}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="packageInfoJson.packages.id">
									<span v-if="scope.row.packageInfoJson.packages">
										{{scope.row.packageInfoJson.packages.map(item=> item.id).join(',')}}
									</span>
								</el-descriptions-item>
							</el-descriptions>
							

							<el-descriptions title="paymentInfoJson" v-if="scope.row.paymentInfoJson">
								<el-descriptions-item label="currency">
									<span>
										{{scope.row.paymentInfoJson.currency}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="original_shipping_fee">
									<span>
										{{scope.row.paymentInfoJson.original_shipping_fee}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="original_total_product_price">
									<span>
										{{scope.row.paymentInfoJson.original_total_product_price}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="platform_discount">
									<span>
										{{scope.row.paymentInfoJson.platform_discount}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="product_tax">
									<span>
										{{scope.row.paymentInfoJson.product_tax}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="seller_discount">
									<span>
										{{scope.row.paymentInfoJson.seller_discount}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="shipping_fee">
									<span>
										{{scope.row.paymentInfoJson.shipping_fee}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="shipping_fee_platform_discount">
									<span>
										{{scope.row.paymentInfoJson.shipping_fee_platform_discount}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="shipping_fee_seller_discount">
									<span>
										{{scope.row.paymentInfoJson.shipping_fee_seller_discount}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="sub_total">
									<span>
										{{scope.row.paymentInfoJson.sub_total}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="tax">
									<span>
										{{scope.row.paymentInfoJson.tax}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="total_amount">
									<span>
										{{scope.row.paymentInfoJson.total_amount}}
									</span>
								</el-descriptions-item>
							</el-descriptions>


							

							<el-descriptions title="receiveInfoJson" v-if="scope.row.receiveInfoJson">
								<el-descriptions-item label="address_detail">
									<span>
										{{scope.row.receiveInfoJson.address_detail}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="address_line1">
									<span>
										{{scope.row.receiveInfoJson.address_line1}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="address_line2">
									<span>
										{{scope.row.receiveInfoJson.address_line2}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="address_line3">
									<span>
										{{scope.row.receiveInfoJson.address_line3}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="address_line4">
									<span>
										{{scope.row.receiveInfoJson.address_line4}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="full_address">
									<span>
										{{scope.row.receiveInfoJson.full_address}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="name">
									<span>
										{{scope.row.receiveInfoJson.name}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="phone_number">
									<span>
										{{scope.row.receiveInfoJson.phone_number}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="postal_code">
									<span>
										{{scope.row.receiveInfoJson.postal_code}}
									</span>
								</el-descriptions-item>
								<el-descriptions-item label="region_code">
									<span>
										{{scope.row.receiveInfoJson.region_code}}
									</span>
								</el-descriptions-item>
							</el-descriptions>



							<div>
								<el-card>
									<div slot="header">
										<span>receiveInfoJson.district_info</span>
									</div>

									<el-table :data="scope.row.receiveInfoJson.district_info" :border="true" size="small"
										v-if="scope.row.receiveInfoJson && scope.row.receiveInfoJson.district_info">
										<el-table-column prop="address_level" label="address_level">
										</el-table-column>
										<el-table-column prop="address_level_name" label="address_level_name">
										</el-table-column>
										<el-table-column prop="address_name" label="address_name">
										</el-table-column>
									</el-table>
								</el-card>

							</div>

							<div>
								<el-card>
									<div slot="header">
										<span>goodsSkuInfoJson.skuArr</span>
									</div>

									<el-table :data="scope.row.goodsSkuInfoJson.skuArr" :border="true" size="small"
										v-if="scope.row && scope.row.goodsSkuInfoJson && scope.row.goodsSkuInfoJson.skuArr">
										<el-table-column type="index" width="50" align="center"
											:label="$t('Storage.tableColumn.no')"></el-table-column>


										<el-table-column prop="currency" label="currency">
										</el-table-column>
										<el-table-column prop="display_status" label="display_status">
										</el-table-column>
										<el-table-column prop="is_gift" label="is_gift">
										</el-table-column>
										<el-table-column prop="original_price" label="original_price">
										</el-table-column>
										<el-table-column prop="package_status" label="package_status">
										</el-table-column>
										<el-table-column prop="platform_discount" label="platform_discount">
										</el-table-column>
										<el-table-column prop="product_id" label="product_id">
										</el-table-column>
										<el-table-column prop="product_name" label="product_name">
											<template slot-scope="scope2">
												<el-popover placement="top" trigger="hover">
													<div class="pre-text">{{ scope2.row.product_name }}</div>
													<span class="over_ellipsis" slot="reference" style="">{{scope2.row.product_name}}</span>
												</el-popover>
											</template>
										</el-table-column>
										<el-table-column prop="rts_time" label="rts_time">
										</el-table-column>
										<el-table-column prop="sale_price" label="sale_price">
										</el-table-column>
										<el-table-column prop="seller_discount" label="seller_discount">
										</el-table-column>

										<el-table-column prop="seller_sku" label="seller_sku">
										</el-table-column>
										<el-table-column prop="shipping_provider_name" label="shipping_provider_name">
										</el-table-column>
										<el-table-column prop="sku_id" label="sku_id">
										</el-table-column>
										<el-table-column prop="sku_image" label="sku_image">
											<template slot-scope="scope2">
												<div>
													<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope2.row.sku_image"
														:preview-src-list="[scope2.row.sku_image]">
														<div slot="error" class="image-slot" style="font-size: 40px;"><i
																class="el-icon-picture-outline"></i></div>
													</el-image>
												</div>
											</template>
										</el-table-column>
										<el-table-column prop="sku_name" label="sku_name">
										</el-table-column>
										<el-table-column prop="sku_type" label="sku_type">
										</el-table-column>
										<el-table-column prop="tracking_number" label="tracking_number">
										</el-table-column>
										<el-table-column prop="item_tax" label="item_tax" min-width="400">
											<template slot-scope="scope2">
												<el-table :data="scope2.row.item_tax" :border="true" size="small">
													<el-table-column prop="tax_amount" label="tax_amount">
													</el-table-column>
													<el-table-column prop="tax_rate" label="tax_rate">
													</el-table-column>
													<el-table-column prop="tax_type" label="tax_type">
													</el-table-column>
												</el-table>
											</template>
										</el-table-column>
									</el-table>
								</el-card>
							</div>

						</div>

					</template>
				</el-table-column> -->

				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

				<el-table-column prop="hyStatusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.hyStatus">{{ $Utils.i18nKeyText(scope.row,'hyStatusName') }}</el-tag>
						<el-tag type="success"
							v-else-if="'10' == scope.row.hyStatus">{{ $Utils.i18nKeyText(scope.row,'hyStatusName') }}</el-tag>
						<el-tag type="warning"
							v-else-if="'20' == scope.row.hyStatus">{{ $Utils.i18nKeyText(scope.row,'hyStatusName') }}</el-tag>
						<el-tag type="danger"
							v-else-if="'30'==scope.row.hyStatus">{{ $Utils.i18nKeyText(scope.row,'hyStatusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'hyStatusName') }}</el-tag>
					</template>
				</el-table-column>

				<!-- orderNum
				orderTime
				tpCode
				tpStatus
				
				remark
				
				updateTime
				createTime -->

				<el-table-column prop="orderNum" :label="'第三方单号'"></el-table-column>
				<el-table-column prop="orderTime" :label="'下单时间'"></el-table-column>
				

				<!-- <el-table-column prop="thirdPlatformCode" :label="$t('i18nn_5b954c149f0b1bdf')"></el-table-column>
				<el-table-column prop="thirdOrderNumber" :label="$t('i18nn_b30717f74d1d91b3')"></el-table-column>
				<el-table-column prop="onlineOrderId" :label="$t('i18nn_737061cefdb6d0af')"></el-table-column>
				<el-table-column prop="isSyncLabelName" :label="$t('i18nn_bdf9dc871938ad08')"></el-table-column> -->
				<el-table-column prop="sku" :label="'SKU'">
					<template slot-scope="scope">
						<div v-if="scope.row.goodsSkuInfoJson && scope.row.goodsSkuInfoJson.skuArr">
							<div v-for="(item,index) in scope.row.goodsSkuInfoJson.skuArr" :key="index">
								{{item.seller_sku}}
								<!-- * {{item.quantity}} -->
							</div>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="tpCode" :label="'tpCode'"></el-table-column>
				<el-table-column prop="tpStatus" :label="'tpStatus'"></el-table-column>

				<!-- <el-table-column prop="shopName" :label="$t('i18nn_b6b08a7daa1de1ab')"></el-table-column>
				<el-table-column prop="warehouseCode" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
				<el-table-column prop="logisticsCode" :label="$t('i18nn_0f85a73e30bce3c5')"></el-table-column>
				<el-table-column prop="orderDate" :label="$t('i18nn_bb74b80c21dcc1a5')"></el-table-column> -->

				<el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column>

				<!-- <el-table-column prop="packingNo" :label="$t('Storage.packing_list._no')">
          <template slot-scope="scope">
            {{ scope.row.packingNo }}
          </template>
        </el-table-column> -->

				<!-- <el-table-column prop="leavePort" :label="$t('Storage.packing_list.Departure_port')"></el-table-column>

        <el-table-column prop="arrivePort" :label="$t('Storage.packing_list.arrive_port')"></el-table-column>

        <el-table-column prop="leaveDate" :label="$t('Storage.packing_list.Departure_Date')"></el-table-column>
				
				

        <el-table-column prop="arriveDate" :label="$t('Storage.packing_list.arrive_Date')"></el-table-column>
				
				<el-table-column prop="receiveComplete" :label="$t('i18nn_e7b4fae7cec06141')"></el-table-column>
				
        <el-table-column prop="packageTypeName" :label="$t('Storage.packing_list.Type')"></el-table-column>
				<el-table-column prop="containerSizeName" :label="$t('i18nn_977ed6286b514ff6')"></el-table-column>

        <el-table-column prop="packCountTotal" :label="$t('Storage.packing_list.quantity')"></el-table-column> -->

				<el-table-column prop="updateTime" :label="'更新时间'"></el-table-column>
				<el-table-column prop="createTime" :label="'创建时间'"></el-table-column>

				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')">
					<template slot-scope="scope">
						<div style="color:#999;">{{ scope.row.workNo }}</div>
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="attachments" :label="$t('Storage.packing_list.attachment')" class="">
            <template slot-scope="scope">
              <div @click="openUploadFile($event, scope.row)">
                <div v-if="scope.row.attachments">
                  <el-link type="primary">...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.attachments.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span></el-link>
                </div>
              </div>
            </template>
        </el-table-column> -->

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="150px" fixed="right" align="left">
					<template slot-scope="scope">

						<!-- <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div> -->
						<!-- <div v-else> -->
						<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->
						<!-- <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button> -->
						<!-- </div> -->
						<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->
						<div>
							<!-- <div style="margin-bottom: 5px;">
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>

								<el-button v-if="'10'==scope.row.status" @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button>
							</div> -->

							<!-- <div style="margin-bottom: 10px;">
                <el-button @click="showDetList($event, scope.row)" type="primary" size="mini" icon="el-icon-view">{{$t('FormMsg.details_View')}}</el-button>
                <el-button @click="openUploadFile($event, scope.row)" type="success" size="mini" icon="el-icon-upload2">{{$t('FormMsg.upload_attachment')}}</el-button>
              </div> -->
							
							<el-button @click="toDetActionView($event, scope.row)" type="primary" size="mini" icon="el-icon-view">{{$t('FormMsg.details_View')}}</el-button>
							

							<!-- <div v-if="'0' == scope.row.status">
                <el-button @click="shipmentsAction($event, scope.row, scope.$index)" type="success" size="mini" icon="el-icon-sell">{{$t('Storage.packing_list.shipments')}}</el-button>
              </div> -->
							<!-- <div style="margin-bottom: 5px;">
								<el-button @click="commitAction($event, scope.row, scope.$index)" type="primary" size="mini"
									icon="el-icon-sell">{{$t('i18nn_ab29edcb476d7186')}}</el-button>

								<el-button @click="removeSendAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-close">{{$t('i18nn_e9d55d41fee9a273')}}</el-button>
							</div> -->
						</div>


					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->



		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.details_View')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="0">
      <div style=""><InWarehouseDetList :packId="packId"></InWarehouseDetList></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->
		<!-- 编辑,发货地址 -->
		<!-- <ThirdOrderReceiverEdit :openTime="orderReceiverEditOpenTime" :row="orderReceiverEditRow" @success="orderReceiverEditSuccess"></ThirdOrderReceiverEdit> -->

		<!--  附件上传 -->
		<!-- <whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess"></whFileUpload> -->
		<!-- 订单拉取 -->
		<pullThirdAppOrder :openTime="openTimePullEcOrder" :row="selPullEcOrderData" @actionSuccess="PullEcOrderSuccess">
		</pullThirdAppOrder>
		
		<!-- 详情查看 -->
		<ThirdAppOrderDet :openTime="openTimeDsDet" :row="DsDetRow"></ThirdAppOrderDet>

		<!-- 更新发货仓库 -->
		<!-- <refreshDeliveryWarehouse :openTime="openTimeRefDeliWh" :ids="RefDeliWhIds" :row="selRefDeliWhData"
			@actionSuccess="PullEcOrderSuccess"></refreshDeliveryWarehouse> -->

	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';

	import {
		ThirdOrder_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';

	// import WSkuInfo from '@/components/StorageCenter/WSkuProduct/WSkuInfo.vue';

	// import InWarehouseDetList from './InWarehouseDetList.vue';
	// import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import pullThirdAppOrder from '@/components/StorageCenter/ThirdApplicationAuth/components/pullThirdAppOrder.vue';
	// import refreshDeliveryWarehouse from '@/components/StorageCenter/Thirdparty/components/refreshDeliveryWarehouse.vue';
	import ThirdAppOrderDet from '@/components/StorageCenter/ThirdApplicationAuth/ThirdAppOrderDet.vue';
	// import ThirdOrderReceiverEdit from '@/components/StorageCenter/Thirdparty/components/ThirdOrderReceiverEdit.vue';

	export default {
		components: {
			// WSkuInfo,
			// InWarehouseDetList,
			// whFileUpload,
			// SelAgentUser,
			whNoSelect,
			pullThirdAppOrder,
			ThirdAppOrderDet,
			// refreshDeliveryWarehouse,
			// ThirdOrderReceiverEdit
		},
		data() {
			return {

				drawerFilter: false,

				multipleSelection: [],

				loading: false,

				loading_det: false,
				//编辑
				orderReceiverEditOpenTime: '',
				orderReceiverEditRow: {},

				openTimePullEcOrder: '',
				selPullEcOrderData: {},

				openTimeRefDeliWh: '',
				RefDeliWhIds: [],
				selRefDeliWhData: {},

				loading_load: false,
				tableData: [],
				
				openTimeDsDet: '',
				DsDetRow: {},
				
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					wh_third_platform: [],
					wh_thrid_Is_sync_Label: [],
					statusList: ThirdOrder_status
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					// agentUser: "",
					// workNo: '',
					// status: '',
					// packingNo: '',
					keyword: "",

					// thirdPlatformCode: "",
					// thirdOrderNumber: "",
					// onlineOrderId: "",
					// shopName: "",
					// whNo: '',
					// wh_thrid_Is_sync_Label: ""
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_third_platform', 'wh_thrid_Is_sync_Label'],
				(data) => {
					this.selectOption.wh_third_platform = data['wh_third_platform'];
					this.selectOption.wh_thrid_Is_sync_Label = data['wh_thrid_Is_sync_Label'];
				});
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
			},
			//打开更多筛选
			openMoreFilter() {
				this.drawerFilter = !this.drawerFilter;
			},
			//查询更多筛选
			queryMoreFilter() {
				this.initData();
				// this.drawerFilter = false;
			},
			i18nFormatter(value) {
				return i18nStatus(value, this.selectOption.statusList)
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			
			
			//去详情
			toDetActionView(event, row) {
				event.stopPropagation();
				// this.$router.push({ name: 'WhDropShippingDet', query: { orderNumber: row.orderNumber } });
				this.openTimeDsDet = new Date().getTime();
				this.DsDetRow = row;
			},

			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.orderReceiverEditOpenTime = new Date().getTime();
			// 	this.orderReceiverEditRow = row;
			// },
			// orderReceiverEditSuccess(){
			// 	this.initData();
			// },

			//生成发货单
			commitAction(event, row) {
				event.stopPropagation();

				let parm = [];

				if (row) {
					//     //单条
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
						return;
					}
					let dataListParm = dataList.map(v => v.id);
					parm = dataListParm;
				}

				this.$confirm('确定生成发货单吗？', this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// {"ids":[11111111111111]}
						this.postData(this.$urlConfig.WhThirdTTPOrderAdd, {ids:parm}, () => {
							this.$message.success(this.$t('tips.submitSuccess'));
						});
					})
					.catch(() => {});
			},

			//删除订单
			// delAction(event, row) {
			// 	event.stopPropagation();
			// 	let parm = [];
			// 	if (row) {
			// 		//     //单条
			// 		parm = [row.id];
			// 	} else {
			// 		//多条
			// 		let dataList = this.multipleSelection;
			// 		if (dataList.length < 1) {
			// 			this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
			// 			return;
			// 		}
			// 		let dataListParm = dataList.map(v => v.id);
			// 		parm = dataListParm;
			// 	}
			// 	this.$confirm('确定删除吗？', this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhThirdOrderDel, {
			// 				ids: parm
			// 			}, () => {
			// 				this.$message.success(this.$t('tips.submitSuccess'));
			// 			});
			// 		})
			// 		.catch(() => {});
			// },
			//撤销发货单
			// removeSendAction(event, row) {
			// 	event.stopPropagation();
			// 	let parm = [];
			// 	if (row) {
			// 		//     //单条
			// 		parm = [row.id];
			// 	} else {
			// 		//多条
			// 		let dataList = this.multipleSelection;
			// 		if (dataList.length < 1) {
			// 			this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
			// 			return;
			// 		}
			// 		let dataListParm = dataList.map(v => v.id);
			// 		parm = dataListParm;
			// 	}

			// 	this.$confirm('确定撤销发货单吗？', this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			// {"ids":[11111111111111]}
			// 			this.postData(this.$urlConfig.WhThirdOrderRemoveSend, parm, () => {
			// 				this.$message.success(this.$t('tips.submitSuccess'));
			// 			});
			// 		})
			// 		.catch(() => {});
			// },
			//拉取最新订单
			pullOrderAction(event, row) {
				event.stopPropagation();
				this.openTimePullEcOrder = new Date().getTime();
				this.selPullEcOrderData = {
					proxyUserId: this.filterData.agentUser
				};
			},
			//
			PullEcOrderSuccess() {
				this.initData();
				// this.$alert
				this.$alert('拉取成功,请稍后刷新订单', this.$t('tips.tipsTitle'), {
					type: 'success'
				});
			},
			
			//获取tk面单
			pullLabelsAction(event,row){
				event.stopPropagation();
				
				let parm = [];
				
				if (row) {
					//     //单条
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
						return;
					}
					let dataListParm = dataList.map(v => v.id);
					parm = dataListParm;
				}
				
				// this.$confirm('确定生成发货单吗？', this.$t('tips.tipsTitle'), {
				// 		// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// 		// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
				// 		type: 'warning'
				// 	})
				// 	.then(() => {
						// {"ids":[11111111111111]}
						this.postData(this.$urlConfig.WhThirdTTPOrderLabel, {ids:parm}, () => {
							this.$message.success(this.$t('tips.submitSuccess'));
						});
					// })
					// .catch(() => {});
			},

			//更新发货仓库
			// refreshWhAction(event, row) {
			// 	event.stopPropagation();
			// 	//多条
			// 	let dataList = this.multipleSelection;
			// 	if (dataList.length < 1) {
			// 		this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
			// 		return;
			// 	}
			// 	let ids = dataList.map(v => v.id);
			// 	this.openTimeRefDeliWh = new Date().getTime();
			// 	this.RefDeliWhIds = ids;
			// },

			//提交信息
			postData(url, formData, callback, type) {
				// HttpTypelet _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.dialogFormVisible = false;
						this.getPageData();

						callback();
					} else {
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},

			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},
			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhThirdTTPOrderPageList, {
						// sortAsc: this.filterData.sortAsc,
						// orderBy: this.filterData.orderBy,
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目

						// workNo: this.filterData.workNo ? this.filterData.workNo : null,
						// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
						// status: this.filterData.status ? this.filterData.status : null,
						// packingNo: this.filterData.packingNo ? this.filterData.packingNo : null,
						keyword: this.filterData.keyword ? this.filterData.keyword : null,
						// thirdPlatformCode: this.filterData.thirdPlatformCode ? this.filterData.thirdPlatformCode : null,
						// thirdOrderNumber: this.filterData.thirdOrderNumber ? this.filterData.thirdOrderNumber : null,
						// onlineOrderId: this.filterData.onlineOrderId ? this.filterData.onlineOrderId : null,
						// shopName: this.filterData.shopName ? this.filterData.shopName : null,
						// warehouseCode: this.filterData.whNo ? this.filterData.whNo : null,
						// isSyncLabel: this.filterData.wh_thrid_Is_sync_Label ? this.filterData.wh_thrid_Is_sync_Label : null,
					})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>